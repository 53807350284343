(function() {

    'use strict';

    /**
     * reverse engineered epeng api
     * based on
     * https://opweb1.vfd2-testnet.de/enterprise/shop.html
     */
    var epeng = (function(){

        var isInitialised = false;

        var json = {};

        var jsonUrlPrexix = '//opweb1.vfd2-testnet.de/enterprise/media';

        var isSoho = false;

        var getSingular = function (value) {
            return value.substr(0, value.length - 1);
        };

        var loadJson = function(name){
            var deferred = $.Deferred();  // eslint-disable-line new-cap

            if (undefined !== json[name]){
                deferred.resolve();
            }
            else {
                $.get(jsonUrlPrexix + '/' + name + '.json', function (response) {  // eslint-disable-line consistent-return


                    var jsonResponse = JSON.parse(response);

                    if ('additionalPrices' === name){
                        json[name] = jsonResponse.tariffGroups;
                    }
                    else {
                        json[name] = jsonResponse[name][getSingular(name)];
                    }

                    deferred.resolve();

                }, 'text');
            }

            return deferred.promise();
        };

        var initialise = function(){

            var deferred = $.Deferred(); // eslint-disable-line new-cap

            if (undefined !== $('.wrapper.enterprise.soho').get(0)){
                isSoho = true;
            }

            if (true === isInitialised){
                deferred.resolve();
            }
            else {
                var jsonDataFiles = ['articles', 'virtualDevices', 'tariffGroups', 'additionalPrices', 'tariffOptions', 'benefits'];

                var promises = [];

                $(jsonDataFiles).each(function(){
                    promises.push(loadJson(this));
                });

                $.when.apply(null, promises).done(function() {
                    isInitialised = true;
                    deferred.resolve();
                });
            }

            return deferred.promise();

        };

        /**
         * dont return anything, instead it changes the tarif object from param
         */
        var addAdditionalData = function(tariff){

            var matchingData;

            $(json.additionalPrices.tariffGroup).each(function(){

                $(this.tariff).each(function(){

                    if (this.id === tariff.id){
                        matchingData = this;
                        return false;
                    }

                    return true;

                });

                if (undefined !== matchingData){
                    return false;
                }

                return true;

            });

            tariff.priceSimOnly = matchingData.simonly.val;
            tariff.priceWithPhone = matchingData.sub.val;

            if (false === isSoho){
                var benefitWithPhone = 0;
                var benefitSimOnly = 0;

                if (undefined !== matchingData.benefit){
                    benefitWithPhone = benefitSimOnly = matchingData.benefit;
                }

                if (undefined !== matchingData.sub.benefit){
                    benefitWithPhone = matchingData.sub.benefit;
                }

                if (undefined !== matchingData.simonly.benefit){
                    benefitSimOnly = matchingData.simonly.benefit;
                }

                tariff.priceSimOnly += benefitSimOnly;
                tariff.priceWithPhone += benefitWithPhone;
            }
        };

        var getTariff = function(tariffId){

            var tariff;

            $(json.tariffGroups).each(function(){

                if (undefined === this.tariff.length){
                    return true;
                }

                $(this.tariff).each(function(){
                    if (tariffId === this.id){
                        tariff = this;
                        return false;
                    }
                    return true;
                });

                return true;
            });

            addAdditionalData(tariff);

            return tariff;

        };

        var getDetailHref = function(tariff, virtualDevice, article){

            var url = ['//opweb1.vfd2-testnet.de/enterprise/shop.html'];

            if (undefined !== virtualDevice.id){
                url.push('?d=' + virtualDevice.id);
            }
            else {
                url.push('?d=' + article.sapArtNo);
            }

            url.push('&t=' + tariff.id);
            url.push('&icmp=hp_ds_172');

            return url.join('');
        };


        var normalizeData = function(tariff, virtualDevice, article){

            var normalizedData = {};

            for (var key in vf.connect.deviceDataPattern){
                normalizedData[key] = undefined;
            }

            normalizedData.id = article.sapArtNo;

            if (undefined !== virtualDevice.name){
                normalizedData.name = virtualDevice.name;
            }
            else {
                normalizedData.name = article.name;
            }

            var prefix = (-1 < article.image.indexOf('/media/img/')) ? '//opweb1.vfd2-testnet.de' : '//shop.vodafone.de';
            normalizedData.imageSrc = prefix + article.image;
            normalizedData.link = getDetailHref(tariff, virtualDevice, article);
            normalizedData.price = article.additionalPrice.priceNet;

            normalizedData.activationCharge = '0';
            normalizedData.monthlyCharge = '' + tariff.priceWithPhone;
            normalizedData.monthlyCharge = '' + normalizedData.monthlyCharge.replace('.', ',');


            return normalizedData;
        };

        var checkIsSapNumberRegEx = function(virtualId){
            var isSapNumberRegEx = /^00\d{6}$/;

            return isSapNumberRegEx.test(virtualId);

        };

        /**
         * Get Article
         */
        var getArticle = function(tariffId, sapArtNo){

            var tariff = getTariff(tariffId);
            var tariffLevel = tariff.tariffLevel;

            var article;

            $(json.articles).each(function(){


                if (sapArtNo !== this.sapArtNo){
                    return true;
                }

                var that = this;

                $(this.additionalPrice).each(function(){

                    if (tariffLevel !== this.tariffLevel){
                        return true;
                    }

                    article = $.extend({}, that);
                    article.additionalPrice = this;

                    return true;
                });

                return true;
            });

            var data = normalizeData(tariff, {}, article);

            return data;

        };

        /**
         * Get Article with lowest Price
         */
        var getArticleByVirtualDevice = function(tariffId, virtualId){

            virtualId = parseInt(virtualId, 10);

            var tariff = getTariff(tariffId);
            var tariffLevel = tariff.tariffLevel;

            var virtualDevice;

            $(json.virtualDevices).each(function(){

                if (this.id === virtualId){
                    virtualDevice = this;
                    return false;
                }
                return true;
            });

            var lowestPrice;
            var article;

            $(virtualDevice.article).each(function(){

                var sapArtNo = this.sapArtNo;

                $(json.articles).each(function(){

                    if (sapArtNo !== this.sapArtNo){
                        return true;
                    }

                    var that = this;

                    $(this.additionalPrice).each(function(){

                        if (tariffLevel !== this.tariffLevel){
                            return true;
                        }

                        var price = 1 * this.priceNet.replace(',', '.');

                        if (undefined === lowestPrice || lowestPrice > price){
                            lowestPrice = price;

                            article = $.extend({}, that);
                            article.additionalPrice = this;
                        }
                        return true;
                    });

                    return true;
                });

            });

            var data = normalizeData(tariff, virtualDevice, article);

            return data;

        };

        var getDeviceDataByVirtualId = function(tariffId, virtualId){

            var article;

            var isSapNumberRegEx = checkIsSapNumberRegEx(virtualId);


            if (false === isSapNumberRegEx){
                article = getArticleByVirtualDevice(tariffId, virtualId);
            }
            else {
                article = getArticle(tariffId, virtualId);
            }

            return article;

        };

        var getTarifData = function(tariffId){

            var tariff = getTariff(tariffId);

            var normalizedData = {};

            for (var key in vf.connect.tariffDataPattern){
                normalizedData[key] = undefined;
            }
            normalizedData.id = tariff.id;

            normalizedData.subId = tariff.tariffLevel;
            normalizedData.name = tariff.name;
            normalizedData.detailLink = tariff.detailPath;

            normalizedData.activationCharge = '0';
            normalizedData.monthlyCharge = '' + tariff.priceSimOnly;

            normalizedData.activationCharge = '' + normalizedData.activationCharge.replace('.', ',');
            normalizedData.monthlyCharge = '' + normalizedData.monthlyCharge.replace('.', ',');

            return normalizedData;

        };

        var getVirtualIdByDeviceName = function(deviceName){

            if ('simonly' === deviceName){
                return 'simonly';
            }

            if (undefined === deviceName.toLowerCase){
                return undefined;
            }

            var virtualId;

            $(json.virtualDevices).each(function(){

                if (undefined === this.name || undefined === this.name.toLowerCase){
                    return true;
                }

                if (this.name.toLowerCase() === deviceName.toLowerCase()){
                    virtualId = this.id;
                    return false;
                }

                if (this.name.toLowerCase().replace('apple ', '') === deviceName.toLowerCase()){
                    virtualId = this.id;
                    return false;
                }

                return true;

            });

            return virtualId;

        };

        var getTariffShopLink = function(tariffId, simonly){
            console.log('missing link', tariffId, simonly);
        };
        var getName = function(){
            return 'epeng';
        };

        return {
            getName: getName,
            initialise: initialise,
            getDeviceDataByVirtualId: getDeviceDataByVirtualId,
            getTarifData: getTarifData,
            getTariffShopLink: getTariffShopLink,
            getVirtualIdByDeviceName: getVirtualIdByDeviceName
        };

    })();

    vf.connect.epeng = epeng;

}(vf));
